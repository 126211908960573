import React from "react";
import { ChatContent } from "./chat/Chat";
import { ChatModeProvider } from "../providers/ChatModeProvider";

export const Gallery = () => {
  return (
    <ChatModeProvider>
      <div className="flex flex-col items-center justify-center h-screen bg-white/95">
        <ChatContent />
      </div>
    </ChatModeProvider>
  );
};
