import { useState } from "react";
import { fetch_post } from "~/lib/utils";

interface FollowUpResponse {
  follow_ups: string[];
}

interface UseFollowUpsProps {
  question: string;
  answer: string;
}

export const useFollowUps = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchFollowUps = async ({ question, answer }: UseFollowUpsProps): Promise<string[]> => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch_post("/follow_ups", {
        question,
        answer,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch follow-up questions");
      }

      const data: FollowUpResponse = await response.json();
      return data.follow_ups;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "An error occurred";
      setError(errorMessage);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchFollowUps,
    isLoading,
    error,
  };
};
