import React, { useState } from "react";
import RootBubble, { BubbleType, Speaker } from "./RootBubbleView";
import CopyMessageButton from "./CopyMessageButton";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
// @ts-ignore
import Logo from "~/assets/images/logo.svg?react";

export default function AssistantBubble({
  type,
  text,
  isGeneratingResponse,
}: {
  type?: BubbleType;
  text: string;
  isGeneratingResponse: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Combine the text and the typing indicator
  const displayText = isGeneratingResponse
    ? `${text}▍` // Use a block character as cursor
    : text;

  return (
    <RootBubble type={type} speaker={Speaker.ASSISTANT} setIsHovered={setIsHovered}>
      <div className="flex items-top w-full gap-2">
        <Logo className="w-8 h-8 border rounded-lg shadow-md bg-white" />
        <span className="w-full mt-1" data-test-id="assistant-bubble-text">
          <Markdown rehypePlugins={[rehypeRaw]} className="reactMarkDown">
            {displayText}
          </Markdown>
        </span>
      </div>

      <CopyMessageButton message={text} isHovered={isHovered} />
    </RootBubble>
  );
}
