import React from "react";
import { ArrowRightIcon } from "lucide-react";

export default function InputBox({
  message,
  setMessage,
  onSubmit,
  isGenerating,
}: {
  message: string;
  setMessage: (message: string) => void;
  isGenerating: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <div className="fixed bottom-0 w-full flex justify-center bg-gradient-to-t from-white via-white to-transparent pt-10">
      <form
        className="flex flex-col items-center justify-center min-w-[700px] max-w-[700px] bg-white border rounded-t-lg px-2 gap-4"
        onSubmit={onSubmit}
      >
        <div className="flex gap-2 w-full mt-2">
          <input
            placeholder="Ask anything..."
            className="w-full bg-transparent p-2 focus:outline-none text-sm border-none focus:ring-0"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
          />
          <button
            disabled={!message || isGenerating}
            className={`p-2 rounded-full w-8 h-8 flex items-center justify-center transition-all duration-300 ${
              isGenerating ? "bg-black" : message ? "bg-black" : "bg-gray-200"
            }`}
          >
            {isGenerating ? (
              <div className="w-4 h-4 bg-white rounded-sm" />
            ) : (
              <ArrowRightIcon
                className={`w-4 h-4 ${message ? "text-white" : "text-gray-500"}`}
                strokeWidth={2.5}
              />
            )}
          </button>
        </div>

        <span className="text-xs text-slate-500 mb-4 text-left w-full ml-4">
          AI can make mistakes. Check important info.
        </span>
      </form>
    </div>
  );
}
