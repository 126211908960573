import React from "react";
import { Clock, Tag, BotIcon as Robot, CircleIcon as AdCircle, MailQuestion } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/components/ui/card";
import { Badge } from "~/shadcn/components/ui/badge";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { useQuery } from "@tanstack/react-query";

interface TelemetryViewProps {
  answer: string;
  isGenerating: boolean;
  onClose: () => void;
}

export function TelemetryView({ answer, isGenerating, onClose }: TelemetryViewProps) {
  if (isGenerating) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-sm text-muted-foreground">
        Awaiting telemetry data...
      </div>
    );
  }
  const { data: telemetry, isLoading } = useQuery({
    queryKey: ["telemetry", answer],
    queryFn: async () => {
      const telemetry = await window.koah.getTelemetry(answer);
      if (telemetry.error) {
        return null;
      }
      return telemetry;
    },
    enabled: !!answer,
  });

  const data = telemetry?.data;
  if (!data || isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-sm text-muted-foreground">
        No telemetry data available
      </div>
    );
  }

  const timingData = [
    {
      name: "Show Ad",
      key: "should_show_ad_ms",
      value: data.should_show_ad_ms,
    },
    {
      name: "Find Ads",
      key: "find_relevant_ads_ms",
      value: data.find_relevant_ads_ms,
    },
    {
      name: "Fetch Ads",
      key: "fetch_ad_ms",
      value: data.fetch_ad_ms,
    },
    {
      name: "Ad Copy",
      key: "generate_ad_copy_ms",
      value: data.generate_ad_copy_ms,
    },
  ];

  const eligiblityChecker = data.ad_eligibility_checker;
  const question = data.question;
  const maxTime = Math.max(
    ...timingData.filter((item) => !isNaN(item.value)).map((item) => item.value),
  );
  const yAxisDomain = [0, Math.ceil(maxTime / 100) * 100];
  const totalTime = data.generate_ad_ms;

  return (
    <div className="flex items-center justify-center z-50">
      <div className="w-full max-w-2xl overflow-y-auto">
        <div className="space-y-4 p-2">
          <div className="text-xl font-semibold text-slate-800 pb-2">Telemetry</div>
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Time</CardTitle>
                <Clock className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totalTime?.toFixed(2)}ms</div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Model</CardTitle>
                <Robot className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{data.model_provider}</div>
                <p className="text-xs text-muted-foreground">{data.model}</p>
              </CardContent>
            </Card>
          </div>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Question</CardTitle>
              <MailQuestion className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent className="flex gap-2 italic">"{question}"</CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Ad Eligibility</CardTitle>
              <AdCircle className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            {eligiblityChecker && (
              <CardContent className="flex gap-2">
                <Badge variant={eligiblityChecker.prohibited ? "destructive" : "secondary"}>
                  {eligiblityChecker.prohibited ? "Prohibited" : "Not Prohibited"}
                </Badge>
                <Badge variant={eligiblityChecker.purchase_intent ? "default" : "secondary"}>
                  {eligiblityChecker.purchase_intent ? "Purchase Intent" : "No Purchase Intent"}
                </Badge>
                <Badge variant={eligiblityChecker.should_show ? "default" : "secondary"}>
                  {eligiblityChecker.should_show ? "Should Show Ad" : "Should Not Show Ad"}
                </Badge>
              </CardContent>
            )}
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Timing Data</CardTitle>
              <Clock className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={timingData}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <XAxis type="number" domain={yAxisDomain} />
                    <YAxis dataKey="name" type="category" width={40} fontSize={12} />
                    <Tooltip formatter={(value, name, props) => [`${value?.toFixed(2)}ms`]} />
                    <Bar dataKey="value" fill="hsl(var(--primary))" barSize={24}>
                      {timingData
                        .filter((entry) => entry.value != null)
                        .map((entry, index) => (
                          <text
                            x={entry.value + 5}
                            y={index * 40 + 20}
                            fill="#888"
                            textAnchor="start"
                            dominantBaseline="middle"
                            key={`label-${index}`}
                          >
                            {entry.value?.toFixed(2)}ms
                          </text>
                        ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Other Details</CardTitle>
              <Tag className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent className="space-y-2 text-sm">
              <div>
                <span className="font-semibold">Ad Type:</span> {data.ad_type}
              </div>
              <div>
                <span className="font-semibold">Content Format:</span> {data.content_format}
              </div>
              <div>
                <span className="font-semibold">Ad Content:</span> {data.ad_content || "None"}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
